<div>
  <mat-card>
    <mat-card-title>OpenAI Initial Thoughts - June 12, 2023</mat-card-title>
    <mat-card-content>
      <p>
        Today people no longer have to remember someone's phone number. They do
        not have to remember directions, or really even any of the street names
        within their town or neighborhood. How does anyone contact anyone or get
        anywhere?! Two simple words ... Google it! Oh, but hold the phone! There
        is a new kid on the block and their name is ChatGPT.
      </p>
      <p>
        ChatGPT is a Generative Pre-trained Transformer that has hit the
        developer world hard in the past month or so. I mentioned this to a
        non-technical person last week and they looked at me like I was talking
        about an imaginary friend 😂.
      </p>
      <p>
        Now with GREAT tech comes GREAT responsibility. From what I have read and
        listened to there are basically two main camps when talking about AI and
        what OpenAI/GPT-4/ChatGPT bring to the table. One camp believes this is a
        great advancement in technology and that amazing tools and solutions will
        be built from this new development. The other camp believes the previous
        sentence to be true, but also believes that "bad actors" could/will abuse
        this tech and horrible things/tools/weapons will be built in the not so
        far away future.
      </p>
      <p>
        I have not decided what camp I fall into yet. More research/testing is needed
        from my end.
      </p>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Azure Functions - February 2, 2023</mat-card-title>
    <mat-card-content>
      <p>
        Where do I start with this one. Friday I was having a love hate
        relationship with az functions. I continue to see the benefit of using
        these small code based functions that are rather powerful when used for
        the correct use case. I also, find that the more I use and research how
        to use this tool the better I get at implementing a standard way of
        running these functions.
      </p>
      <p>
        The issue that I am running into with using these at work is that they are
        still too new and too unregulated to be apart of my everyday workflow. Take
        last week, I wanted to do a simple batch process with an az func that would kick
        off every Monday morning and build a file aftering hitting an API and a db to
        gather the data. After building the file, it would then SFTP the data over to
        a third party server. Nothing I just wrote is that complicated when talking about
        moving data; however, when you add in networking and firewall rules/privileges and
        what the company has locked down you start falling down a worm hole of wasting time.
      </p>
      <p>
        In the end it just made sense to use a production ready solution and continue to use
        az funcs for small poc type builds/projects.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Logseq - January 9, 2023</mat-card-title>
    <mat-card-content>
      <p>
        Last Friday, one of my co-workers did a demo of an older, but new piece
        of software to us called Logseq. I have always been a huge nerd around
        note taking apps, I love lists and reminders on my phone for todos to
        get me organized, and was using the Notes app for documentation or note
        taking at work. Notes was fine, but once I saw what Logseq could do I
        was instantly sold.
      </p>
      <p>
        Logseq is a lightweight piece of software that is based around graphs.
        This is what separates it from Notes or OneNote in my eyes. You can
        easily link and tag pages and comments to other pages with simple
        commands and symbols making it very easy to search and organize your
        data for future needs.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Java vs C# - January 5, 2023</mat-card-title>
    <mat-card-content>
      <p>
        In 2022 I would say that probably 70% of the code I wrote was
        java/kotlin, 20% was javascript, and the remaining 10% was C#. That
        being said, I felt like it was a very easy transition back a forth
        between java/kotlin and C#. Whereas switching between javascript and a
        statically typed language took a bit of time to get back in the swing of
        things.
      </p>
      <p>
        Due to the fact that I write more code in java/kotlin, I would say that
        I am much more comfortable with writing backend services using Spring
        Boot, pom files (or gradle.build files for some Kotlin projects I have
        worked on), Spring annotations, junit for testing all have been built
        into my muscle memory over the years of work week repetition. That is
        where I see a major different when switching back and forth between
        java/kotlin and C#.
      </p>
      <p>
        The dotnet ecosystem is very nice and helpful if you know what you are
        doing. The learning curve is not crazy hard once you get your feet wet,
        but it is a bit different when switching from pom/gradle files over to
        csproj files and nuget package managers.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Happy New Year! - January 1, 2023</mat-card-title>
    <mat-card-content>
      <p>NEW YEAR, NEW BLOG POST!!</p>
      <p>
        This year ... I will be focusing on pretty similar topics. Mostly, I
        like to chat about what tech I am learning and fiddling with in my free
        time (currently: playing with lua and creating plugins for Neovim,
        teaching myself python for better script writing/automation, and started
        to teach myself golang, but lost steam there when I couldn't see a huge
        difference between it and other backend languages for server side
        calls).I have acquired that trend from doing this for a living. If I am
        no using something for a specific purpose, I prune that "thing" from my
        arsenal to open up room for something useful.
      </p>
      <p>
        This year ... I think I want to start blogging more about what I am
        doing at work in broad strokes. Obviously, I cannot get to specific with
        implementations or in the weeds on perhaps why I am working on a
        solution, but I do think it is important to discuss some of the stuff
        that fills my day between 8-5 Mon-Fri.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>New Blog - October 12, 2022</mat-card-title>
    <mat-card-content>
      <p>Pushing new blog to test new stack for deployment</p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>i3 + Tmux - August 2, 2022</mat-card-title>
    <mat-card-content>
      <p>
        i3 and Tmux ... this combo did not last long for me. At first I really
        enjoyed the window manager i3, but shortly I discovered that it was more
        trouble than it was worth for my use case.
      </p>
      <p>
        What you will learn about me in this blog is that I love to try and test
        out new tech, but I will not shy away from admitting when something does
        not work for me or if it is sub-par to my standards. I would not say
        that i3 is a bad product, it just does not fall into my flow. I like to
        work off a very large monitor 99% of the time when coding or writing. i3
        out of the box seems like it is better for someone working from their
        laptop or a smaller monitor. The reason I call this out is because I
        could not get the resolution to work properly with Ubuntu + i3 without
        doing some serious Googling and trouble shooting with my Dell Gaming
        monitor. I used to have time to really dig into things that do not
        matter, but ever since having a bebé ... time is of the essence. I like
        things to be quick, easy, and reliable. This does not mean throwing
        quality out the window! When I am building a product, I put these 4
        values into everything I create.
      </p>
      <p>
        Tmux on the other-hand is my new favorite Terminal add-on ... plugin ...
        whatever you want to call it. I use tmux for both personal and work
        computing now. It allows me to have multiple sessions running in the
        terminal (one for grep, one for workspace, another for scripts or a
        different workspace, etc). Love the product and will continue to use for
        the forseeable future.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Vim Or Bust - March 31, 2022</mat-card-title>
    <mat-card-content>
      <p>
        According to Google, Vim (text editor) was first released in November of
        1991. A 30 year old text editor! You may ask yourself, why on earth are
        you writing about this?! Great question! I actually hated Vim when I
        first became an engineer. I thought, why the hell would you use this?!
        Also, how the hell do you write to a file?!?!
      </p>
      <p>
        Sorry, got taken away there for a second. Anyway ... I finally did
        figure out how to write to a file and so so much more!
      </p>
      <p>
        Vim at the beginning is a bit cumbersome and clunky. It takes a lot of
        practice to get good, but once you figure it out and get that muscle
        memory down, it can totally be a GameChanger. After a few years of using
        Vim plugins for IDE's, I recently leveled up by discovering vim-plug for
        your .vimrc file. I think my first plugin was a fuzzy finder, then an
        LSP, and then a color scheme lol 😂.
      </p>
      <p>
        Very excited to see where this new discovery will take me over the next
        year. Currently, only using the Vim flow for front-end development
        (using it right now). Have not found a great workflow for Java
        development yet, but might try using the eclim plugin which is from what
        I understand like running a headless version of the Eclipse IDE
        underneath the Vim text editor. We shall see!
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title
      >Java Jdbc Template and PostgreSQL - February 25, 2022</mat-card-title
    >
    <mat-card-content>
      <p>
        Lots to discuss since my last post. I have added a new repository into
        the Dough Management API that will be in charge of all things Postgres.
        The PostgresBlogRepository is currently implementing an interface with
        two functions on it (findBlog and saveBlog) ...
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title
      >Organizing Scripts in Vue - February 21, 2022</mat-card-title
    >
    <mat-card-content>
      <p>
        Good morning, Dev world. Recently, I have been thinking about how to
        organize my Vue.js scripts within my dough-dev project. I started
        playing around with Vue 3 and liked it, but it felt more like a Angular
        application where you create components and organize everything like an
        application instead of a HTML page.
      </p>
      <p>
        With that being said, I have made the decision to stick with Vue 2 and
        keep this blog very simple and not turn it into an application until
        necessary.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Algorithms - February 14, 2022</mat-card-title>
    <mat-card-content>
      <p>
        Second post of the day alert! Today was a good day of balance and
        productivity. Time with family, time at work, time to code, time to
        workout, time for Valentine's Day dinner from our favorite Chinese
        restaurant, and additional time to code and watch Netflix with the
        Project Manager (this is my nickname for my wife, she keeps me on track
        most of the time 🙃).
      </p>
      <p>
        Tonight, I have been working on a quicksort algorithm written in Java. I
        do this occasionally when I want to dust off a language that I plan on
        using in the future or when I want to focus my mind on solving problems
        step by step in small chunks. In tonight's code both cases were true.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Back at it! - February 14, 2022</mat-card-title>
    <mat-card-content>
      <p>
        I have been thinking about what to do with this blog for quite some time
        now, and I've decided my main objective will be to document my
        development goals, struggles, and accomplishments for the forseeable
        future. FYI - I have not installed a spellcheck for vscode yet, so
        apologies for any typo errors.
      </p>
      <p>
        For my first little project (you could refer to it as a story if you are
        an Agile fan) I am going to be implementing a "Post" button using Vue.js
        to pop a modal, allow a user (me for now) to type an entry, then save it
        to a postgresql database through a NodeJS api that I built. A follow up
        story will then be to select the blog entry from the db and display it
        on a card.
      </p>
      <p>
        As I am doing these stories/projects, I will also be playing around with
        the design of this site (trying to master mobile first design). I think
        it will be cool to start very vanilla using little css to launch this
        blog and watch it change over time.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Blog Resurrection - September 18, 2021</mat-card-title>
    <mat-card-content>
      <p>
        Bringing my blog back to life to document what is going on in my dev
        life, as I work full time as a Software Engineer for a popular Sporting
        Goods retailer in the United States; as well as, experiment/research new
        tech at night or on my weekends. Currently, playing around with Vue.js
        as I build out the Dev Details page to compare a lighter weight library
        compared to Angular (my choice for building out web apps).
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title
      >Schedule: Makers VS Managers - April 7, 2018</mat-card-title
    >
    <mat-card-content>
      <p>
        The art of a person's schedule. The well known calendar invite. Some
        (let's refer to this group as "Managers") live by this tool and cannot
        function properly without checking it every morning to plan the upcoming
        day. However, for others (let's refer to these people as "Makers") see
        this tool as more of a creative killer, a buzzkill, a wet blanket (I
        think you get my point) that just becomes a part of life that you learn
        to live with because society deems it "normal".
      </p>
      <p>
        Growing up in a very "Always show up 5 minutes early" type environment,
        it was definitely instilled in me at a very young age that it is rude to
        show up late for an appointment/meeting/commitment. Humans as a whole,
        respect the value of time and do our best to manage it, control it, and
        try to optimize it throughout our lives. The issue that I have run into
        with this type of mindset is that creation/creativity does not live on a
        timed schedule. Now, of course there are ways to set aside time for
        creative thoughts and process; however, when you get "real" with
        yourself, sometimes creativity just strikes during your morning shower,
        or in the middle of a meeting, or maybe right before you fall asleep. My
        initial instinct when this little beam of brilliance hits me is to write
        it down, sketch it up, or code that motha until the cows come home.
      </p>
      <p>
        Unfortunately, herein lies the issue ... I cannot just get up and leave
        a meeting to go indulge on a little creative snack. I find if my entire
        day is following a "Managers" schedule that very little creative work
        gets done that day. Usually what occurs is a lot of planning, and
        discussing, and perhaps some problem generation gets done, but usually
        very little out of the box thinking occurs. On the flip side, if I block
        out maybe the first four hours of my morning, just to hold from being
        scooped up by the meeting troll ... then more times than not, something
        cool and new is generated or brought into existence to play a role
        (maybe) in this wonderful world called Design.
      </p>
    </mat-card-content>
  </mat-card>
</div>
