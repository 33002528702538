// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA1eB7-v92FeAZ1J9ZauadarrROjFgv9vM",
    authDomain: "dough-blog.firebaseapp.com",
    projectId: "dough-blog",
    storageBucket: "dough-blog.appspot.com",
    messagingSenderId: "250245775684",
    appId: "1:250245775684:web:23508c6439a928d6c95711",
    measurementId: "G-5MYRRXHNP3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
